// React Basic and Bootstrap
import FeatherIcon from 'feather-icons-react';
import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import SectionTitle from '../../components/Shared/SectionTitle';

class Portfolio extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="portfolio">
          <Container>
            <Row>
              <Col md="4" sm="6" xs="12">
                <SectionTitle title="Portfolio" desc="" isLeft />
              </Col>
            </Row>
            <Row>
              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Legislative Strategy</h5>
                  </div>
                </div>
              </Col>

              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Healthcare Reform</h5>
                  </div>
                </div>
              </Col>

              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Telecommunications</h5>
                  </div>
                </div>
              </Col>

              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Technology</h5>
                  </div>
                </div>
              </Col>

              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Taxes</h5>
                  </div>
                </div>
              </Col>

              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Energy Rights-of-Way</h5>
                  </div>
                </div>
              </Col>

              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Privacy Rights</h5>
                  </div>
                </div>
              </Col>

              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Education</h5>
                  </div>
                </div>
              </Col>

              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Real estate</h5>
                  </div>
                </div>
              </Col>

              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Renewable energy</h5>
                  </div>
                </div>
              </Col>

              <Col md="4" sm="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="check-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Transportation</h5>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default Portfolio;
