import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';

const settings = {
  lazyload: true,
  nav: false,
  mouseDrag: true,
  items: 1,
  autoplayTimeout: 3000,
  responsive: {
    600: {
      items: 2,
      gutter: 20,
    },
    1050: {
      items: 3,
      gutter: 20,
    },
  },
  autoplay: true,
};

class ReviewsSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cols: 4,
      page: 0,
      totalPages:
        this.props.logos.length % 3 === 0
          ? this.props.logos.length / 3
          : Math.floor(this.props.logos.length / 3) + 1,
    };
    this.updateWindowSize.bind(this);
  }

  updateWindowSize = () => {
    if (window.outerWidth >= 1230) {
      this.setState({ itemCount: 3, cols: 4 });
    } else if (window.outerWidth >= 970 && window.outerWidth < 1230) {
      this.setState({ itemCount: 3, cols: 4 });
    } else if (window.outerWidth <= 970) {
      this.setState({ itemCount: 1, cols: 12 });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSize);
  }
  render() {
    return (
      <React.Fragment>
        <Row className="justify-content-center">
          <Col lg="12" className="mt-4">
            <div id="customer-testi" className="tiny-three-item">
              <TinySlider settings={settings}>
                {this.props.logos.map((el, index) => (
                  <div key={index} className="flex justify-center">
                    <img
                      src={el}
                      data-src={el}
                      alt=""
                      style={{ height: 100, width: 'auto' }}
                      className={'d-block mx-auto'}
                    />
                  </div>
                ))}
              </TinySlider>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ReviewsSlider;
