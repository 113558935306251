// React Basic and Bootstrap
import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';

const settings = {
  lazyload: true,
  nav: false,
  mouseDrag: true,
  items: 1,
  autoplay: true,
};

//Import Icons

//Light Box
import '../../../node_modules/react-image-lightbox/style.css';

//Import Components
import ProcessBox from '../../components/Shared/ProcessBox';
import SectionTitle from '../../components/Shared/SectionTitle';

// import images
import marco from '../../assets/images/marco.webp';
import marco10 from '../../assets/images/marco10.webp';
import marco2 from '../../assets/images/marco2.webp';
import marco3 from '../../assets/images/marco3.webp';
import marco4 from '../../assets/images/marco4.webp';
import marco5 from '../../assets/images/marco5.webp';
import marco6 from '../../assets/images/marco6.webp';
import marco7 from '../../assets/images/marco7.webp';
import marco8 from '../../assets/images/marco8.webp';
import marco9 from '../../assets/images/marco9.webp';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processes: [
        {
          id: 1,
          title: 'Consulting',
          desc: 'With a deep understanding of the legislative process and a commitment to help clients solve problems, we assist our clients in navigating the complex political landscape.',
        },
        {
          id: 2,
          title: 'Lobbying',
          desc: "Our team provides full-time, active lobbying services that cater to your organization's specific legislative needs, whether it is a particular bill or industry-wide effort, on a year-round basis across the state.",
        },
        {
          id: 3,
          title: 'Litigation',
          desc: 'We are experienced legal practitioners specializing in civil litigation, with a particular focus on FELA, commercial disputes, products liability, class actions, negligence, and insurance.',
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section"
          style={{ padding: 0, paddingTop: 200 }}
          id="about"
        >
          <Container>
            <Row className="align-items-center">
              <Col md="6">
                <div className="section-title">
                  <h4 className="title">About</h4>
                  <Row className="mt-4 mb-4 pt-2 pt-sm-0 d-block d-md-none">
                    <TinySlider settings={settings}>
                      <img src={marco} className="about-image" />
                      <img src={marco2} className="about-image" />
                      <img src={marco3} className="about-image" />
                      <img src={marco4} className="about-image" />
                      <img src={marco5} className="about-image" />
                      <img src={marco6} className="about-image" />
                      <img src={marco7} className="about-image" />
                      <img src={marco8} className="about-image" />
                      <img src={marco9} className="about-image" />
                      <img src={marco10} className="about-image" />
                    </TinySlider>
                  </Row>
                  <h6 className="text-primary mb-4">
                    Marco Gonzales, Principal
                  </h6>
                  <p className="text-muted">
                    Marco Gonzales represents clients in the New Mexico
                    Legislature and the United States Congress. He has a firm
                    grasp of the rules of the legislative process, a strong
                    sense of how the institutions of government operate and a
                    solid understanding of what works and what doesn't. He
                    recognizes that the overriding goal of public policy
                    advocacy is to assist clients in solving their problems, as
                    well as to further enhance their image and reputations with
                    policymakers. He regularly testifies on behalf of clients in
                    front of numerous legislative committees including the
                    Legislative Finance, Senate Finance, House Appropriations
                    and Finance, Senate Corporations, House Business and
                    Industry, Senate and House Judiciary, Senate Public Affairs
                    and House Government and Health Affairs Committees.
                  </p>
                  <p className="text-muted">
                    Marco Gonzales is President and CEO at Capitol Complex
                    Strategies and he has than 30 years of experience
                    specializing in public policy, lobbying and litigation. He
                    advises clients on a diverse range of public policy matters
                    involving legislative strategy, appropriations and agency
                    process at both the state and federal levels. His public
                    policy practice involves many substantive areas, including
                    healthcare, renewable energy, telecommunications,
                    technology, taxes, energy rights-of-way, privacy rights,
                    education, transportation, social media, commercial space
                    development and real estate. Marco is an experienced
                    practitioner in the area of civil litigation, primarily
                    involving the Federal Employers Liability Act (FELA)
                    commercial disputes, products liability, class actions,
                    negligence and insurance defense.
                  </p>
                  <p className="text-muted">
                    Marco was Managing Partner of Modrall Sperling's Santa Fe
                    office and Chair of the firm's Public Policy/Lobby Group
                    before starting Capitol Complex Strategies. Marco also
                    worked in the United States Senate as a Legislative
                    Assistant to Senator Pete V. Domenici for 10 years. During
                    his tenure in the Senate, Marco covered issues related to
                    the Senate Armed Services Committee, the Senate
                    Appropriations Subcommittee on Defense, the Senate Judiciary
                    Committee, the Senate Appropriations Subcommittee on
                    Commerce, Justice, State and the Judiciary and the Senate
                    Veterans' Affairs Committee for the Senator.
                  </p>
                  <p className="text-muted">
                    Marco, who was born in Santa Fe, New Mexico, and comes from
                    a family deeply rooted in its traditions and history, has an
                    extensive educational and legal background. He holds a J.D.
                    from Georgetown University Law Center, which he obtained in
                    1998, and a B.A. in World Politics from The Catholic
                    University of America, which he earned in 1990. Marco is
                    admitted to practice law in New Mexico, the U.S. District
                    Court for the District of New Mexico, the U.S. Court of
                    Appeals for the Tenth Circuit, and the United States Supreme
                    Court. In addition to his legal career, Marco also ran for
                    Congress in New Mexico's Third District in 2008 and is a
                    popular political analyst on local television and radio
                    programs.
                  </p>
                  <div className="mt-4">
                    <a href="#contact" className="btn btn-primary  mouse-down">
                      <i className="uil uil-phone"></i> Contact Us
                    </a>
                  </div>
                </div>
              </Col>

              <Col
                md="6"
                className="mt-4 mt-sm-0 pt-2 pt-sm-0 d-none d-md-block"
              >
                <TinySlider settings={settings}>
                  <img src={marco} className="about-image" />
                  <img src={marco2} className="about-image" />
                  <img src={marco3} className="about-image" />
                  <img src={marco4} className="about-image" />
                  <img src={marco5} className="about-image" />
                  <img src={marco6} className="about-image" />
                  <img src={marco7} className="about-image" />
                  <img src={marco8} className="about-image" />
                  <img src={marco9} className="about-image" />
                  <img src={marco10} className="about-image" />
                </TinySlider>
              </Col>
            </Row>
          </Container>
        </section>
        <section
          className="section"
          style={{ padding: 0, paddingTop: 200 }}
          id="services"
        >
          <Container className="mt-60">
            {/* section title */}
            <SectionTitle
              isLeft={true}
              title="Services"
              desc="Start working with us to receive comprehensive consulting and lobbying services that can help you connect and achieve your public policy goals."
            />

            <Row>
              {/* process box */}
              <ProcessBox processes={this.state.processes} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
