// React Basic and Bootstrap
import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import Components
import SectionTitle from '../../components/Shared/SectionTitle';

// Formik Validation
import { useFormik } from 'formik';
import * as Yup from 'yup';

const SERVICE_ID = 'service_1c6tby7';
const TEMPLATE_ID = 'template_m5bwxcf';
const USER_ID = 'CaXe2hPL8m4RI2Vfc';

const Contact = () => {
  const [succemsg, setSucceMsg] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: '',
      email: '',
      subject: '',
      url: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Enter Your Name'),
      email: Yup.string().required('Email is required'),
      subject: Yup.string().required('Subject is required'),
    }),
    onSubmit: (values) => {
      const form = document.getElementById('contact-form');
      emailjs
        .sendForm(SERVICE_ID, TEMPLATE_ID, form, USER_ID)
        .then(() => setSucceMsg(true));
    },
  });
  return (
    <React.Fragment>
      <section
        className="section"
        style={{ padding: 0, paddingTop: 180, paddingBottom: 50 }}
        id="contact"
      >
        <Container className="mt-60" id="contact">
          {/* section title */}
          <SectionTitle
            isLeft={true}
            title="Contact Us"
            desc="+1 505-226-1458"
          />
          <Row>
            <Col lg="4" md="6" className="mt-4 pt-2">
              <Card className="rounded shadow border-0">
                <CardBody>
                  <h4>Schedule a Consultation</h4>
                  <div className="custom-form mt-3">
                    <div id="message"></div>
                    <Alert
                      color="primary"
                      isOpen={succemsg}
                      toggle={() => {
                        setSucceMsg(false);
                      }}
                    >
                      Contact details sent successfully.
                    </Alert>
                    <Form
                      method="post"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>
                        <Col className="col-12">
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Name <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="user"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control ps-5"
                              placeholder="Name :"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ''}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col className="col-12">
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Email <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control ps-5"
                              placeholder="Email :"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ''}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col className="col-12">
                          <div className="mb-3">
                            <Label className="form-label">
                              Subject <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="book"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="subject"
                              id="subject"
                              type="subject"
                              className="form-control ps-5"
                              placeholder="Subject :"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.subject || ''}
                              invalid={
                                validation.touched.subject &&
                                validation.errors.subject
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.subject &&
                            validation.errors.subject ? (
                              <FormFeedback type="invalid">
                                {validation.errors.subject}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col className="col-12">
                          <div className="mb-3">
                            <Label className="form-label">
                              Message <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="message-circle"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <textarea
                              name="comments"
                              id="comments"
                              rows="4"
                              className="form-control ps-5"
                              placeholder="Message :"
                            ></textarea>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-12">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="btn btn-primary"
                              value="Submit"
                            />
                            <div id="simple-msg"></div>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg="8" md="6" sm="12" className="ps-md-3 pe-md-3 mt-4 pt-2">
              <Card className="border-0 shadow rounded">
                <div className="map map-height-two map-gray">
                  <iframe
                    title="Find Us"
                    src="//www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25925.80258542283!2d-105.9524281!3d35.6837648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87185043b872406b%3A0xad8604efadb0bb1!2sNew%20Mexico%20State%20Capitol!5e0!3m2!1spt-BR!2sbr!4v1679573290106!5m2!1spt-BR!2sbr"
                    style={{ border: 0 }}
                    className="rounded"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
