import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';

class ProcessBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.processes.map((process, key) => (
          <Col md="4" className="mt-4 pt-2" key={key}>
            <Card className="work-process border-0 rounded shadow h-100 text-center">
              <CardBody>
                <h4 className="title">{process.title}</h4>
                <p className="text-muted para">{process.desc}</p>
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default ProcessBox;
