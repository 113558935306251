import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';

import bg01 from '../../assets/images/personal/bg01.png';
import bg02 from '../../assets/images/personal/bg02.png';
import bg03 from '../../assets/images/personal/bg03.png';
import bg04 from '../../assets/images/personal/bg04.png';
import bg05 from '../../assets/images/personal/bg05.png';

const settings = {
  lazyload: true,
  nav: false,
  mouseDrag: true,
  items: 1,
  autoplay: true,
};

class Hero extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="bg-home rtl-personal-hero bg-light d-flex align-items-center"
          id="home"
        >
          <div style={{ position: 'absolute', minHeight: '800px' }}>
            <TinySlider settings={settings}>
              <img
                src={bg01}
                style={{ minHeight: '800px', objectFit: 'cover' }}
              />
              <img
                src={bg02}
                style={{ minHeight: '800px', objectFit: 'cover' }}
              />
              <img
                src={bg03}
                style={{ minHeight: '800px', objectFit: 'cover' }}
              />
              <img
                src={bg04}
                style={{ minHeight: '800px', objectFit: 'cover' }}
              />    <img
              src={bg05}
              style={{ minHeight: '800px', objectFit: 'cover' }}
            />
            </TinySlider>
            <div className="bg-overlay opacity-4"></div>
          </div>
          <Container style={{ marginTop: '100px' }}>
            <Row className="align-items-center">
              <Col lg="8" md="9">
                <div className="title-heading mt-4">
                  <h1 className="display-3 fw-bold mb-3 hero-title white-text">
                    Capitol Complex
                    <br />
                    <span className="element hero-subtitle white-text">
                      Strategies
                    </span>
                  </h1>
                  <p className="para-desc white-text">
                    Expert guidance and full-time lobbying for successful public
                    policy advocacy in New Mexico.
                  </p>
                  <div className="mt-4 pt-2">
                    <a href="#contact" className="btn btn-primary mt-2 me-2">
                      <i className="uil uil-schedule"></i> Schedule a
                      Consultation
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Hero;
