// React Basic and Bootstrap
import React, { Component } from 'react';
import { Col, Collapse, Container, Row } from 'reactstrap';

// Import Generic Components
import About from './About';
import Contact from './Contact';
import Hero from './Hero';
import Portfolio from './Portfolio';

//Import Images
import SectionTitle from '../../components/Shared/SectionTitle';

//Import Containers
import ReviewsSlider from '../../components/Shared/ReviewsSlider';

const logos = Array(24)
  .fill(0)
  .map((_, i) => require(`../../assets/images/clients/${i + 1}.png`));

class IndexMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faq: [
        {
          id: 0,
          title: 'Do I need the services of CCS?',
          text: 'That is an excellent question, and the answer depends on the particular needs of your organization and the issue at hand. Please contact us, and we will gladly discuss your unique situation.',
          isOpen: false,
        },
        {
          id: 1,
          title: 'What is the cost of representation?',
          text: 'The cost of representation varies widely depending on the issue. We analyze each scenario on an individual basis and comprehensively explain the available options for your organization. We welcome you to contact us and we would be pleased to discuss your specific situation with you.',
          isOpen: false,
        },
        {
          id: 2,
          title: 'Can you guarantee a particular outcome if we hire your firm?',
          text: 'We are confident in our abilities and are happy to share references. However, we cannot guarantee a specific outcome on any legislative issue. It is important to exercise caution when any firm claims to guarantee a specific outcome in the unpredictable realm of politics.',
          isOpen: false,
        },
        {
          id: 3,
          title: 'Is your firm compliant with all State Regulations?',
          text: "At our firm we value transparency and honesty. All required filings and registrations are up to date and on file with the New Mexico Secretary of State's office. We are pleased to provide you with any documentation you may require, or they can be found on the Secretary of State's website.",
          isOpen: false,
        },
      ],
    };
    this.scrollNavigation = this.scrollNavigation.bind(this);
  }

  componentDidMount() {
    document.body.classList = '';
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation() {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* Hero Start */}
        <Hero />

        {/* About */}
        <About />

        <section
          className="section"
          style={{ padding: 0, paddingTop: 80 }}
          id="portfolio"
        >
          <Row>
            <Col className="col-12">
              <Portfolio />
            </Col>
          </Row>
          {/* Partner */}
          <section
            className="section mt-0"
            style={{ paddingTop: 150, paddingBottom: 0 }}
          >
            <Container>
              {/* section title */}
              <SectionTitle
                title="Principal's Representative Clients"
                desc=""
              />

              {/* partners */}
              <Row className="justify-content-center">
                <ReviewsSlider logos={logos} colClass="mt-4" />
              </Row>
            </Container>
          </section>
        </section>

        <Contact />

        <section className="section" id="faq">
          <Container className="mt-60 pb-60">
            {/* section title */}
            <SectionTitle
              isLeft={true}
              title="Frequently Asked Questions"
              desc=""
            />

            <Row>
              <div>
                <div className="accordion" id="buyingquestion">
                  {this.state.faq.map((item) => {
                    return (
                      <div className="accordion-item rounded" key={item.id}>
                        <h2 className="accordion-header">
                          <button
                            id="headingOne"
                            className={`accordion-button border-0 bg-light ${
                              !item.isOpen ? 'collapsed' : ''
                            } `}
                            type="button"
                            onClick={() => {
                              const faq = this.state.faq;
                              const newState = faq.map((prev) => {
                                const temp = Object.assign({}, prev);
                                if (temp.id === item.id) {
                                  temp.isOpen = !temp.isOpen;
                                }
                                return temp;
                              });
                              this.setState({ faq: newState });
                            }}
                          >
                            {item.title}
                          </button>
                        </h2>
                        <Collapse
                          toggler="#headingOne"
                          className="border-0"
                          isOpen={item.isOpen}
                        >
                          <div className="accordion-body text-mute">
                            {item.text}
                          </div>
                        </Collapse>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Row>
          </Container>
        </section>

        <div className="position-relative" style={{ marginTop: 80 }}>
          <div className="shape overflow-hidden text-footer">
            <svg
              viewBox="0 0 2880 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M720 125L2160 0H2880V250H0V125H720Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default IndexMain;
