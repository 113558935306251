import React, { Component, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import Layout from './components/Layout/';

// Import Css
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';

import 'semantic-ui-css/semantic.min.css';

// import "./assets/css/colors/default.css";

// Include Routes
import routes from './routes/allRoutes';

function withLayout(WrappedComponent, hasDarkTopBar) {
  // ...and returns another component...
  /* eslint-disable react/display-name */
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar}>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  Loader() {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={this.Loader()}>
            <Switch>
              {routes.map((route, idx) =>
                route.isWithoutLayout ? (
                  <Route
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    key={idx}
                  />
                ) : (
                  <Route
                    path={route.path}
                    exact
                    component={withLayout(route.component, route.isTopbarDark)}
                    key={idx}
                  />
                ),
              )}
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
